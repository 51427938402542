<template>
  <div>
    <top-nav :showLogo="false" title="CDK"></top-nav>
    <div class="top-bg">
      <div class="title">
        <p>CDK</p>
      </div>
      <img src="../../assets/image/rwa/top-bg.png">
    </div>
    <div class="container">
      <div class="top-containet">
        <div class="title"> {{ $t('激活') }}CDK</div>
        <div class="input-cont">
          <div class="input-div">
            <input type="text" @focus="promptText=''" v-model="cdk_code"
                   :placeholder="$t('请输入')"/>
            <img v-if="cdk_code" @click="emptyValue" class="empty-icon" src="../../assets/image/rwa/empty-icon.png">
          </div>
        </div>
        <div class="prompt-cont">
          {{ promptText }}
        </div>
      </div>

      <div class="my-cdk-title">
        <div class="title">{{ $t('我的') }}CDK</div>
        <!--        <div class="sort-cont">-->
        <!--          <div class="sort-item">-->
        <!--            <span>按时间</span>-->
        <!--            <div class="sort-icon-cont">-->
        <!--              <img src="../../assets/image/rwa/cdk/upper-triangular.png">-->
        <!--              <div style="height: 0.15rem"></div>-->
        <!--              <img src="../../assets/image/rwa/cdk/lower-triangle.png">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="sort-item">-->
        <!--            <span>按效果</span>-->
        <!--            <div class="sort-icon-cont">-->
        <!--              <img src="../../assets/image/rwa/cdk/upper-triangular.png">-->
        <!--              <div style="height: 0.15rem"></div>-->
        <!--              <img src="../../assets/image/rwa/cdk/lower-triangle2.png">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>


      <div class="combo-cont">
        <van-list
            v-model="loading"
            :finished="finished"
            :loading-text="$t('加载中')"
            :finished-text="$t('没有更多了')"
            @load="onLoad"
        >
          <div class="combo-item" v-for="(item,index) in list" :key="index">
            <div class="info-cont">
              <div class="info-left title"> {{ item.title }}</div>
              <div class="info-right status">
                {{ item.state == 0 ? $t('未使用') : $t('已使用') }}
              </div>
            </div>
            <div class="info-cont">
              <div class="info-left ">{{ $t('种草值') }}</div>
              <div class="info-right number">
                {{ item.power || '-' }}
              </div>
            </div>
            <div class="info-cont">
              <div class="info-left ">CDK{{ $t('编号') }}</div>
              <div class="info-right number">
                {{ item.cdk_code }}
                <img @click="copyData(item.cdk_code)" class="copy-icon" src="../../assets/image/rwa/cdk/copy-icon.png">
              </div>
            </div>

            <div class="info-cont">
              <div class="info-left ">{{ $t('日期') }}</div>
              <div class="info-right time">{{ item.created_at }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <div class="bottom-cont">
      <div class="button-cont">
        <router-link to="cdkRecord">
          <div class="order-but">
            <img class="order-icon" src="../../assets/image/rwa/order-icon.png"/>
          </div>
        </router-link>
        <div class="confirm-but" @click="postUseCdk">
          {{ $t('确定') }}
        </div>
      </div>
    </div>

    <div class="activation-success" v-if="showActivationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
          <div class="title">{{ $t('激活成功') }}</div>
          <div class="text">{{ cdk_code }}</div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">

        <div class="_but" @click="onShowActivationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {getCdkList, postUseCdk} from "../../api";

export default {
  name: "cdk",
  components: {TopNav},
  data() {
    return {
      cdk_code: '',
      list: [],
      loading: false,
      finished: false,
      page: 1,
      promptText: '',
      showActivationSuccess: false,
    }
  },
  methods: {
    emptyValue() {
      this.cdk_code = ''
      this.promptText = ''
    },
    onShowActivationSuccess() {
      this.showActivationSuccess = !this.showActivationSuccess
      if (!this.showActivationSuccess) {
        this.cdk_code = ''
      }
    },
    postUseCdk() {
      let that = this;
      if (that.cdk_code.length < 16) {
        that.promptText = that.$t('请输入正确的CDK')
        return
      }
      that.promptText = ''
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      postUseCdk({'cdk_code': that.cdk_code})
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              that.onShowActivationSuccess()
            } else {
              setTimeout(() => {
                that.$toast(data.message);
              }, 500)
            }
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    onLoad() {
      this.getCdkList()
    },
    getCdkList() {
      let that = this;
      getCdkList({'page': that.page})
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              if (this.page >= data.data.last_page) {
                // 数据全部加载完成
                that.finished = true
              } else {
                that.page = data.data.current_page + 1
              }
              let arr = that.list
              arr.push(...data.data.data)
              that.list = arr;
            }
          })
          .finally(() => {
            that.loading = false;
          })
    }
    ,
    copyData(text) {
      let that = this;
      if (text) {
        this.$copyText(text).then(
            function (e) {
              that.$toast(that.$t('复制成功'));
            }
        );
      }
    }
    ,
  }
}
</script>

<style scoped lang="less">
.top-bg {
  position: relative;

  .title {
    position: absolute;
    left: 2.29rem;
    top: 3rem;
    font-size: 1.43rem;
    color: #E7BD71;
  }

  .subtitle {
    font-size: 0.93rem;
    color: #6E6045;
  }

  img {
    width: 100%;
  }
}

.container {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  top: 11rem;
  padding: 0 1rem;
  padding-bottom: 5rem;
  z-index: 2;
}

.top-containet {
  padding: 1.2rem 1.25rem;
  border-radius: 1rem;
  background: linear-gradient(180deg, #E8BD70 0%, #EDD185 100%);

  .title {
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #333333;
  }
}

.input-cont {
  padding: 1rem 1.35rem;
  background: #FFF8EA;
  border-radius: 0.71rem;
  margin-top: 0.68rem;


  .title {
    float: left;
    color: #333;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .input-div {
    text-align: left;
    line-height: 1.29rem;

    input {
      width: 80%;
      text-align: left;
      font-size: 1rem;
      color: #333333;
      border: none;
      background-color: transparent;
    }

    input::-webkit-input-placeholder {
      color: #999999;
    }

    span {
      font-size: 0.79rem;
      color: #666;
    }

    .empty-icon {
      margin-left: 0.2rem;
      float: right;
      width: 1.29rem;
    }
  }
}

.prompt-cont {
  width: 90%;
  margin: auto;
  color: #E64937;
  font-size: 0.86rem;
  padding-top: 0.23rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.my-cdk-title {
  overflow: hidden;
  padding: 1.2rem 0.25rem;

  .title {
    padding-left: 1rem;
    float: left;
    font-size: 1rem;
    color: #E7BD71;
  }

  .sort-cont {
    float: right;
    display: flex;
    align-items: center;
    justify-content: right;

    .sort-item {
      padding-left: 1rem;
      font-size: 0.93rem;
      color: #B5904E;

      .sort-icon-cont {
        position: relative;
        top: 0.1rem;
        display: inline-block;
        padding-left: 0.3rem;

        img {
          width: 0.43rem;
          display: block;
        }
      }
    }
  }
}

.combo-cont {
  .combo-item {
    padding: 0.9rem 1.45rem;
    background: #1B1B1B;
    border-radius: 1.07rem;
    line-height: 2.57rem;
    font-size: 0.93rem;
    margin-bottom: 0.71rem;

    .info-cont {
      overflow: hidden;
      color: #6E6045;

      .info-left {
        float: left;
      }

      .info-right {
        float: right;
      }

      .title {
        font-size: 1.07rem;
        color: #E7BD71;
      }

      .status {
        color: #B5904E;
        font-size: 0.86rem;
      }

      .number {
        color: #E7BD71;
        font-size: 1.07rem;
      }

      .copy-icon {
        margin-left: 0.1rem;
        width: 1.14rem;
      }

      .time {
        font-size: 0.86rem;
        color: #6E6045;
      }
    }
  }
}

.button-cont {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0.9rem 1rem;
  box-sizing: border-box;
  background: #151515;
  color: #000;
  z-index: 2;

  .order-but {
    float: left;
    display: flex;
    width: 20%;
    height: 3.07rem;
    align-items: center;
    justify-content: center;
    background: #40341D;
    border-radius: 1.54rem;

    .order-icon {
      width: 1.5rem;
    }
  }

  .confirm-but {
    float: right;
    text-align: center;
    width: 75%;
    height: 3.07rem;
    font-size: 1.29rem;
    text-align: center;
    font-weight: 600;
    line-height: 3.07rem;
    background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.54rem;
  }
}

.activation-success {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;

  .success-info-cont {
    position: relative;
    width: 70vw;
    margin: auto;
    border-radius: 1.07rem;

    ._bg {
      width: 100%;
    }

    .success-info {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      width: 90%;
      margin: auto;
      text-align: center;

      .title {
        font-size: 1.14rem;
        font-weight: bold;
        color: #E7BD71;
        padding-bottom: 1.1rem;
      }

      .text {
        font-size: 1.14rem;
        color: #E7BD71;
      }


    }

    ._but {
      position: absolute;
      bottom: 2.1rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 85%;
      line-height: 2.5rem;
      color: #000;
      text-align: center;
      font-size: 1.14rem;
      background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
      border-radius: 1.43rem;
    }
  }
}
</style>